.taskList {
    margin-right: unset !important;
}

.actions:hover {
    cursor: pointer;
}

.kanban-overflow::-webkit-scrollbar {
    height: 10px;
    background-color: #F5F5F5;
}

.kanban-overflow::-webkit-scrollbar-thumb {
    background-color: var(--mainColor);
    border-radius: 5px;
}