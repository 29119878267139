.benifits-drop-down{
    z-index: 1;
}
.benifits-drop-down.active {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    max-height: 300px;
    overflow-y: auto;
}
.benifits-drop-down.active.dark-mode{
    background-color: var(--mainCardColor);
}

.benifits-drop-down::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.benifits-drop-down::-webkit-scrollbar-thumb {
    background-color: var(--mainColor);
    border-radius: 5px;
}

.benifits-drop-down>ul {
    list-style: none;
    padding-left: 0%;
    margin: unset;
}

.benifits-drop-down>ul>li {
    height: 50px;
}

.benifits-drop-down>ul>li:hover {
    background-color: var(--mainColor);
}