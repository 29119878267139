:root {
  --mainColor: #AFE581;
  --mainbgDarkColor: rgb(22, 28, 36);
  --mainHeaderDarkColor: rgb(22, 28, 36, 0.8);
  --mainCardColor: rgb(33, 43, 54);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto';
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--mainColor);
  border-radius: 5px;
}


a {
  text-decoration: none !important;
  color: #000000 !important;
}

a.dark-mode-text {
  color: #fff !important;
}

.dark-mode {
  background-color: rgb(22, 28, 36);
  --tw-text-opacity: 1;
  color: rgb(226 232 240/var(--tw-text-opacity));
}

.card.dark-mode {
  --tw-bg-opacity: 1;
  /* background-color: rgb(30 41 59/var(--tw-bg-opacity)); */
  background-color: rgb(33, 43, 54);
  color: rgb(226 232 240/var(--tw-text-opacity));
}

/*   body  background-color: rgb(22, 28, 36);
header background-color: rgba(22, 28, 36, 0.8);
cards     background-color: rgb(33, 43, 54);

text 2nd focused     color: rgb(145, 158, 171);
 */


nav.navbar {
  position: fixed !important;
  top: 0;
  width: 100%;
  background-color: #FEFFFD;
  z-index: 100;
}

nav.navbar.navbar-dark {
  background-color: rgb(22, 28, 36, 0.8);
  color: #fff;
}

.main-container {
  margin-top: 10rem !important;
  /* max-width: 90% !important; */
}

div.collapse.navbar-collapse>ul.mr-auto.navbar-nav {
  gap: 50px;
}

div.collapse.navbar-collapse>ul.mr-auto.navbar-nav.auth-gap {
  gap: 20px
}

.auth-nav {
  gap: 30px;
}

.bg-primary {
  background: #AFE581 !important;
}

.btn-primary {
  background-color: #AFE581 !important;
  color: #000000 !important;
  border: unset !important;
  width: 167px;
  height: 53px;
  border-radius: 30px !important;
}

.nav-item>a.active {
  text-decoration: underline #AFE581 !important;
}

.nav-item:hover,
.nav-item::before {
  text-decoration: underline #AFE581;
  transform: scaleX(1);
}

.btn-secondary {
  background-color: #FEFFFD !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  /* border: unset !important; */
  width: 167px;
  height: 40px;
  border-radius: 15px !important;
}

.nav .job-card-image {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  min-height: 100px;
  min-width: 100px;
}

.home-top-btns>.col {
  padding: unset;
}

.job-card-image>img {
  margin: auto;
}

footer {
  background-color: #E9ECEF;
}

footer.dark-mode {
  background-color: var(--mainbgDarkColor);
}

.footer.navbar-nav>li {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.categories {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  min-height: 100px;
}

.categories:hover {
  background-color: #AFE581;
}

.form-check-input:checked {
  background-color: #AFE581 !important;
  border-color: #AFE581 !important;
}

.form-control:focus,
.form-select:focus {
  border-color: rgb(26 136 70/30%) !important;
  box-shadow: 0 0 0 0.25rem rgb(26 136 70 / 30%) !important;
}