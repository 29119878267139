select.btn {
    -webkit-appearance: none;
}

select.btn option {
    text-align: left;
}

.searchButton {
    cursor: cell !important;
}