.detail-view {
    border-radius: 15px !important;
}
.avatar {
    margin: 0 auto;
    padding: 0;
    border: 2px solid var(--input-border-color);
    width: 200px;
    border-radius: 50%;
    height: 200px;
    display: block;
}
.profile_info{
    table-layout: fixed;
    width: 100%;
}
.profile_info tr td{
    padding: 1rem;
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}