.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.has-search .form-control {
  padding-left: 2.375rem;
  background: #E9ECEF;
  border-radius: 30px;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}


.subscribe-email {
  background: #FFFFFF;
  border: 0.5px solid #000000 !important;
  border-radius: 30px !important;
  padding-left: 4rem !important;
}

.subsribe-button {
  margin-left: -30px !important;
}

.four_zero_four_bg {
  background-image: url('/public/404.gif');
  height: 400px;
  background-position: center;
}

.toast-progress,
.toast-progress-success {
  background: #AFE581 !important;
}