.company-logo {
  min-height: 220px;
  max-height: 220px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px
}

.shared {
  max-height: 25px;
}

.job-menus>li {
  border: unset;
  cursor: pointer;
}

.job-menus>li.dark-mode {
  background-color: var(--mainCardColor);
}

.job-menus>li.active {
  background-color: #AFE581;
  border: unset;
  border-radius: 10px;
}

.benefits {
  padding: 7px 10px;
  background-color: transparent;
  border: 2px solid #E9ECEF;
  border-radius: 8px;
  margin-right: 10px;
  margin-top: 5px;
}

.benefits:hover {
  background-color: #AFE581;
}

.badges {
  background-color: #E9ECEF;
  border: none;
  font-weight: 700;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
}

.logo img {
  display: flex;
  align-items: center;
  margin: 20px 10px;
}

.interest_form {
  background-color: #E9ECEF;
  border-radius: 10px;
  padding: 40px;
}

.recruitment {
  position: absolute;
}

.recruitment>div.col {
  border-radius: 10px;
  border: 1px solid #A1A1A1;
  height: 400px;
}

.main-process {
  position: relative;
  height: 500px;
}

.dots {
  border-radius: 40px;
  background: rgba(217, 217, 217, 0.61);
  top: 37%;
  position: absolute;
  width: 100%;
}

.dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #AFE581;
}