.languageBadge {
    background-color: #E9ECEF !important;
    color: #000;
}

.jobCard {
    border-radius: 20px !important;
}

.jobCard-radius {
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
}

.jobCard:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.post-image {
    min-height: 135px;
}

.post-image .box-1 {
    min-height: 120px;
    min-width: 120px;
}

.image-col {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 10px;
}

.logo-image {
    height: inherit;
    max-height: 100px;
    max-width: 100px;
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.company-image-detail {
    height: inherit;
    max-height: 100px;
    object-fit: contain;
}

.job-title {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.bg-light {
    background-color: #eee !important;
}

.post-image .box-1 {
    transition: transform .2s;
}

.job-card-body:hover .post-image .box-1.box-transform {
    transform: scale(1.5);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}

.job-detail-job-card {
    min-width: 250px;
    max-width: 250px;
    width: 250px;
    border-radius: 12px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}

.job-card-details {
    border-radius: 20px !important;
    border-top-right-radius: unset !important;
    border-top-left-radius: unset !important;
}

.post-desc {
    max-width: 30%;
    width: 30%;
}

.post-salary {
    max-width:25%;
    width: 25%;
}

.post-details {
    max-width: fit-content;
    width: fit-content;
}

.bookmark {
    max-width: fit-content;
    width: fit-content;
}