.candidate-image {
    width: inherit;
    border-radius: 15px;
    max-height: 300px;
    height: 300px;
}

.candidate-profile-card {
    border-radius: 30px !important;
    cursor: pointer;
}

.candidate-profile-card .card-footer {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}