.btn-primary.primary {
    background-color: black !important;
    color: #fff !important;
    height: 45px;
}

.btn-secondary {
    border-radius: 30px !important;
    height: 45px;
}
.top-options{
    color:#AFE581 !important; 
}
.formfield{    
    width: 600px;
    height: 50px;
    border-radius: 8px;
    border: 1px solid #b4b9be;
}
.card{
    border-radius: 15px !important;
}