.profile-cards>div.row {
    margin-top: 3rem;
}

.bg-secondary.language-badge {
    background: #E9ECEF !important;
    color: #000;
    max-width: fit-content;
}
.cv{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dotted #b6babe;
    background-color: #E9ECEF;
    padding: 20px 50px;
}
.editor>div.ck.ck-reset.ck-editor.ck-rounded-corners{
    display: -webkit-inline-box;
    flex-wrap: wrap-reverse;
    width: 100%;
}
.editor>div.ck.ck-reset.ck-editor.ck-rounded-corners>div{
    width: 100%;
}
.editor>div.ck.ck-reset.ck-editor.ck-rounded-corners>div>div>div>div{
    border: 1px solid #ccced1;
}
.editor>div.ck.ck-reset.ck-editor.ck-rounded-corners>div.ck.ck-editor__main{
    height: 300px;
    border-radius: 0.8;
}
.editor>div.ck.ck-reset.ck-editor.ck-rounded-corners>div.ck.ck-editor__main>div{
    height: 100%;
    border-radius: 0.375rem;
}
.benifitBtn{
    border-radius: 10px !important;
}
.benifitBtn:hover{
    background-color: #AFE581 !important;
}
/* #ccced1 */